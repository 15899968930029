
// import FBLogin from "@/components/FBLogin.vue";
// import GoogleLogin from "@/components/GoogleLogin.vue";
// import { LOGIN_USER, FETCH_USER_PROFILE } from "@/store/actionTypes.js";
import { validationMixin } from "vuelidate";
import {
  required,
  email,
  minLength,
  maxLength,
  password,
} from "vuelidate/lib/validators";

export default {
  name: "login",
  // mounted: function(){
  //   console.log("mounted the login page");
  //   location.reload(true);
  // },
  components: {
    // FBLogin
    // GoogleLogin
  },
  mixins: [validationMixin],
  props: {
    error: { type: String, default: "" },
    successMessage: { type: String, default: "" },
  },
  mounted: function () {
    if (this.$route.query.verified) {
      this.successMessage = "Email verified, you can login now!";
    } else if (this.$route.query.reset) {
      this.successMessage =
        "Password change success, you can login with new password now!";
    }
  },
  created: function () {
    console.log("Route parameters are ", this.$route.params);

    let routeParams = this.$route.params;
    if (Object.keys(routeParams).length > 0) {
      this.successMessage = routeParams.successMessage;
    }
  },
  data: () => ({
    form: {
      password: "",
      email: "",
    },
    // userSaved: false,
    sending: false,
    lastUser: null,
  }),
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
      },
    },
  },
  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.email = null;
      this.form.password = null;
    },
    saveUser() {
      this.sending = true;

      // Instead of this timeout, here you can call your API
      // window.setTimeout(() => {
      //   this.userSaved = true;
      //   this.sending = false;
      //   this.clearForm();
      // }, 1500);
    },
    async validateUser() {
      this.$v.$touch();
      var user = {
        password: this.form.password,
        email: this.form.email,
      };
      var self = this;
      try {
        let response = await this.$auth.loginWith("local", { data: user });
        console.log("response: ", response);
      } catch (e) {
        try {
          console.log(e.response);
          self.error = e.response.data.errors.error[0];
          self.successMessage = "";
        } catch (e2) {
          self.error = "Something went wrong, please try again later.";
          self.successMessage = "";
        }
      }

      // if (self.$auth.loggedIn) {
      //   let splits = self.$auth.strategy.token.get().split(" ");
      //   self.$axios.setToken(splits[splits.length - 1], "Token");
      //   self.$store.dispatch("profile/FETCH_USER_PROFILE").then(
      //     response => {
      //       console.log(response);
      //     },
      //     error => {
      //       console.error(
      //         "Got nothing from server. Prompt user to check internet connection and try again"
      //       );
      //     }
      //   );
      // }

      if (!this.$v.$invalid) {
        // this.saveUser();
      }
    },
    loginWithGoogle(event) {
      var self = this;
      try {
        console.log("logging in with google");
        console.log("route in google login is ", this.$route.query.redirect);
        this.$auth
          .loginWith("google", { params: { prompt: "select_account" } })
          .then(() => {
            console.log("After logging in google", this.$route.query.redirect);
            event.currentTarget.classList.add("btn--loading");
            let redirectUrl = this.$route.query.redirect || "/";
            console.log("redirct uri is", redirectUrl);
            this.$router.push(redirectUrl);
          });
      } catch (e) {
        console.log("login failed.");
      }
    },
    async loginWithFacebook() {
      var self = this;
      console.log("logging in with facebook");
      await this.$auth.loginWith("facebook");
    },
    registerUser() {
      this.$router.push({ path: "/register" });
    },
  },
};
